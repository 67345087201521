<template>
    <div v-if="loader">
        Loading...
    </div>
    <div v-if="! loader">
        <CourierGlobalHeader :global_err="global_err" />
        <div v-if="global_err == ''">
                       
            <div class="package_section package_validation_section">
                <div class="container">
                    <div class="table_wrap">
                        <div class="cell_wrap">
                            <div v-if="step === 'initial'" class="empty_package">
                                <img src="images/thankyou_page_box.png" alt="Package" style="width: 30%;">
                                <p>   ...עוד רגע החבילה אצלכם  </p>
                                <p> {{box_number}} עמדו מול תיבה מספר </p>
                                <!--<a href="#" class="comman_anchor" @click="openBox">לפתיחה לחצו כאן </a>-->
                                <div v-if="isWaiting" class="waiting-state">
                                <div class="waiting-spinner"></div>
                                    <p>אנא המתן</p>
                                </div>
                                <a v-else href="#" class="comman_anchor" @click="openBox">לפתיחה לחצו כאן </a>
                            </div>
                
                            <!--<div v-if="step === 'ready'" class="empty_package">
                                <img src="images/thankyou_page_box.png" alt="Package" style="width: 30%;">
                                <a href="#" class="comman_anchor" @click="openBox">פתיחת תיבה</a>
                            </div>-->

                            <div v-if="step === 'complete'" class="empty_package">
                                <img src="images/thankyou_page_box.png" alt="Package">
                                <p>אנא סגרו היטב את תיבה מספר {{box_number}}</p>
                                <p>תודה ולהתראות</p>
                            </div>
                
                            <div v-if="step === 'error'" class="empty_package">
                                <p>{{errorMessage}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
  
  <script>
    
    import BackendModel from "../../models/BackendModel";
    import CourierGlobalHeader from "./CourierGlobalHeader";
    import('./courier/css/style.css')
    
    export default {
        name: 'EndUserPointPackagePickup',
        components:{
            CourierGlobalHeader,
        },
        data() {
            return {
                loader: false,

                step: 'initial',
                box_number: '',
                errorMessage: '',
                uuid: '',
                isWaiting: false,

                errs:'',
                global_err : ''
            }
        },
        async mounted() {
            this.uuid = this.$route.query.id;
            const backendModel = new BackendModel();
            
            try {
                console.log('uuid:' + this.uuid);
                const res = await backendModel.courierE_Request('/Api/service/backoffice/pickup_package', 
                                { uuid: this.uuid });

                console.log('res:');
                console.log(res);
                if (res.data.res === 'OK') {
                    this.box_number = res.data.package.box_number;
                } else if (res.data.res === 'NOK') {
                    this.step = 'error';

                    switch (res.data.err) {
                        case 'INVALID':
                            this.errorMessage = 'הקישור אינו תקין';
                            break;
                        case 'COLLECTED': {
                            this.errorMessage = `החבילה נאספה ב ${res.data.delivery_time}`;
                            let deliveryDate = new Date(res.data.delivery_time);
                            let formattedDate = `${deliveryDate.getDate().toString().padStart(2, '0')}/${(deliveryDate.getMonth() + 1).toString().padStart(2, '0')}/${deliveryDate.getFullYear()}`;
                            this.errorMessage = `החבילה נאספה ב ${formattedDate}`;
                            break;
                        }
                        case 'EXPIRED':
                            this.errorMessage = 'פג תוקפו של הקישור';
                            break;
                        default:
                                this.errorMessage = 'אירעה שגיאה';
                    }
                } else {
                    this.step = 'error';
                    this.errorMessage = 'אירעה שגיאה';
                }

            } catch (error) {
                this.step = 'error';
                this.errorMessage = 'אירעה שגיאה';
            }
        },
        methods: {
            /*confirmLocation() {
                this.step = 'ready';
            },*/
            async openBox() {
                if (this.isWaiting) return;
                this.isWaiting = true;

                const backendModel = new BackendModel();
                try {
                    console.log('opening box for uuid:' + this.uuid);
                    const res = await backendModel.courierE_Request('/Api/service/backoffice/confirm_pickup', 
                                     { uuid: this.uuid });
                    console.log('res.data.res:');
                    console.log(res.data.res);
                    console.log('res.data:');
                    console.log(res.data);
                    if (res.data.res === 'OK') {
                        this.step = 'complete';
                     } else {
                        this.step = 'error';
                        this.errorMessage = 'אירעה שגיאה בפתיחת התיבה';
                }
                } catch (error) {
                    this.step = 'error';
                    this.errorMessage = 'אירעה שגיאה';
                } finally {
                    this.isWaiting = false;
                }
                
            }
        }
    }
  </script>

<style>
    @import 'courier/css/style.css';
</style>
<style scoped>
.waiting-state {
  text-align: center;
  margin: 20px 0;
}

.waiting-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.waiting-state p {
  margin-top: 10px;
  font-size: 18px;
}
</style>